:root {
  --orangeTeal1: #025259;
  --orangeTeal2: #007172;
  --orangeTeal3: #F29325;
  --orangeTeal4: #D94F04;
  --orangeTeal5: #F4E2DE;
  --background: #025259;
  --nice-white: #F4F4F4;
  --toggler-icon-color: #FFFFFF;

  /* Text sizes */
  --text-size-small: 0.875rem;  /* 14px */
  --text-size-small-medium: .93rem;     
  --text-size-medium: 1rem;     /* 16px */
  --text-size-large: 1.25rem;   /* 20px */
  --text-size-xlarge: 1.5rem;   /* 24px */
  --text-size-xxlarge: 2rem;    /* 32px */
  --text-size-xxxlarge: 2.5rem; /* 40px */
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: var(--background);
  color: var(--orangeTeal5);
}

.jumbotron {
  background-color: #0073e6;
  color: #fff;
}

.card {
  background-color: var(--nice-white);
}

.card .title,
.card-title {
  color: var(--orangeTeal2);
  font-size: var(--text-size-xxxlarge); 
  font-weight: bold; 
}

footer {
  background-color: #0073e6;
}

footer a {
  text-decoration: none;
  margin: 0 10px;
  color: var(--orangeTeal5);
}

footer p {
  margin-top: 10px;
  font-size: var(--text-size-small);
}

.about-title {
  font-size: var(--text-size-xxlarge); /* Example size, adjust as needed */
  color: var(--orangeTeal2);
}

.about-subtitle {
  font-size: var(--text-size-xxlarge); /* Example size, adjust as needed */
  color: var(--orangeTeal1);
  margin-top: 1rem;
}

.about-text {
  font-size: var(--text-size-large); /* Example size, adjust as needed */
  margin-bottom: 1rem;
}


.navbar-custom {
  background-color: var(--orangeTeal1) !important;
}

.navbar-custom .navbar-brand,
.navbar-custom .nav-link {
  color: var(--orangeTeal5) !important;
}

.navbar-custom .navbar-toggler {
  border-color: var(--orangeTeal5) !important;
}

.navbar-custom .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.container {
  padding: 20px;
}

.title {
  margin: 20px 0;
  text-align: center;
  color: var(--orangeTeal1);
  font-size: var(--text-size-xlarge);
}

.service-card {
  background-color: var(--nice-white);
  border: 1px solid var(--orangeTeal4);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-card .price {
  font-weight: bold;
  color: var(--orangeTeal3);
  font-size: var(--text-size-medium);
}

.card-text {
  font-size: var(--text-size-large);
}

.service-card .details {
  /* font-style: italic; */
  color: var(--orangeTeal4);
  font-size: var(--text-size-medium);
}

.service-card ul {
  padding-left: 20px;
}

.service-card li {
  font-size: var(--text-size-medium);
}

.service-area-card {
  background-color: var(--nice-white);
  border: 1px solid var(--orangeTeal4);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center; 
}

.flat-rates-title {
  color: var(--orangeTeal2);
  font-size: var(--text-size-xxlarge);
  font-weight: bold;
}

.flat-rates-text,
.flat-rates-list {
  font-size: var(--text-size-large);
  color: var(--black);
  text-align: center; 
  margin: 0 auto;
}

.flat-rates-list {
  list-style: none;
  padding: 0;
}

.flat-rates-list li {
  display: flex;
  justify-content: center;
  padding: 5px 0;
}

.review-card {
  background-color: var(--nice-white);
  border: 1px solid var(--orangeTeal4);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-card .card-body {
  font-size: var(--text-size-medium);
  /* color: var(--orangeTeal1); */
}

.review-text {
  font-size: var(--text-size-large);
  color: var(--black);
}

.review-footer {
  border-top: 1px solid var(--orangeTeal3);
  padding-top: 10px;
  text-align: right;
  font-size: var(--text-size-large);
  color: var(--orangeTeal4);
}
